<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Which statement accurately describes the function of a drying agent during the process of
        reaction workup?
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-n6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uci51LCPrelab1Q1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {
          text: "Drying agents are introduced into a reaction mixture to accelerate the boiling process, leading to the solvent's rapid evaporation.",
          value: 'accelerateBoiling',
        },
        {
          text: 'To prevent the transfer of water into the reaction solution, drying agents are applied to the hands of the chemist conducting the reaction workup.',
          value: 'removeWaterFromHands',
        },
        {
          text: 'Before starting the reaction workup, drying agents are applied to the laboratory bench to thoroughly remove any water.',
          value: 'removeWaterFromBenchtop',
        },
        {
          text: "Drying agents are used to eliminate any remaining water from an organic solution, enabling the complete isolation and drying of the reaction's target molecule from all solvents.",
          value: 'removeWaterFromReaction',
        },
      ],
    };
  },
};
</script>
